<template>
  <div>
    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <h4 class="card-title">
          Схемы
        </h4>
        <v-select
          v-model="schemas"
          class="select"
          :options="defaultSchemas"
          @option:deselecting="deselectSchema"
          @option:selecting="selectSchema"
          multiple
        />
      </div>
    </div>

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <h4 class="card-title">
          Таблицы
        </h4>
        <v-select
          v-model="tables"
          :options="defaultTables"
          @option:deselecting="deselectTable"
          class="select"
          multiple
        />
      </div>
    </div>

    <b-col>
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1 save-button"
          v-bind:disabled="isloading"
          @click="startSync"
      >
          <b-spinner v-if="isloading" small/>
          {{ isloading ? 'Пожалуйста, подождите...' : 'Синхронизация' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import { BCard, BCol, BRow, BCardText, BLink, BButton, BSpinner } from 'bootstrap-vue'
import vSelect from 'vue-select'
import crudModule from '../../libs/crud_module'
import Ripple from 'vue-ripple-directive'

const syncModule = crudModule({
    entity_name: 'sync',
})
const startSync = crudModule({
    entity_name: 'sync/run',
})


function findKeyByValue(obj, value) {
  for (const key in obj) {
      if (obj[key].includes(value)) {
          return key;
      }
  }
  return null;
}

export default {
  components: {
      BCard, 
      BCol, 
      BRow, 
      BCardText, 
      BLink,
      BButton,
      BSpinner,
      vSelect,
      Ripple
  },
  directives: {
      Ripple
  },
  data() {
      return {
          tables: [],
          schemas: [],
          defaultTables: [],
          defaultSchemas:[],
          res: {},
          isloading: false,
      }
  },
  async created() {
    await syncModule.fetchItems()
    .then(res => {
      this.defaultSchemas = Object.keys(res.data.schemas)
      this.defaultTables = res.data.tables
      this.res = res.data
    })
  },
  methods: {
    selectSchema(value) {
      const res = this.res.schemas[value].filter(item => !this.tables.includes(item))
      this.tables.push(...res)
    },
    deselectSchema(value) {
      const res = this.res.schemas[value]
      const resultArray = this.tables.filter(item => !res.includes(item))
      this.tables = resultArray
    },
    deselectTable(value) {
      if (this.schemas.length > 0);
        const res = findKeyByValue(this.res.schemas, value)
        this.schemas.pop(res)
    },
    async startSync() {
      this.isloading = true
      const payload = {
        tables: this.tables,
        isTransaction: true
      }
      console.log(payload)
      await startSync.createItem(payload)
        .then(res => {
          console.log(res)
          this.isloading = false
        })
        .catch(e => {
          console.log(e)
          this.isloading = false
        }) 
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>